<template>
<div>
   <CNav variant="tabs">
    <CNavItem @click.native="changeTab('allcoinsbalance')" >Balances</CNavItem>
    <CNavItem @click.native="changeTab('openOrders')" >Orders</CNavItem>
    <CNavItem @click.native="changeTab('settings')"  active > settings</CNavItem>
  </CNav>
   <component v-bind:is="stepComponent"   > </component>
</div>
</template>

<script>

import settings from './settings.vue'
import openOrders from './openOrders.vue'
import allcoinsbalance from './allcoinsbalance.vue'


export default {
  name: 'exchanges',
  data () {
    return {
      current_tab: 'settings',
      stepComponent : settings
    }
  },
  components: {
    settings,
    openOrders,
    allcoinsbalance
  },
  methods: {
       changeTab(componente) {
         if (componente == "openOrders"){
           this.stepComponent = openOrders
         } else if (componente == "settings") {
           this.stepComponent = settings
         }else if (componente == "allcoinsbalance") {
               console.log(componente)
           this.stepComponent = allcoinsbalance
         }
       },
  },
    computed: {
      
    }
}

</script>
