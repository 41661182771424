<template>
    <div >
    <h1>{{msg}}</h1>
       <input v-model="coinPair" >
        <button
          class="btn btn-primary btn-margin"
          @click="queryPairOeders()">
          Get Pair orders
        </button> 
        <button
          class="btn btn-primary btn-margin"
          @click="queryAllPairOeders()"
           style="float: right;">
          Get All orders orders
        </button> <span style="float: right;">OJO, Solo de puede usar cada 700 segundos. Se puede usar en : {{timer_binance_getallOrders}} segundos</span>
        <CDataTable :items="crypto_orders" :fields="fields" sorter  column-filter hover pagination />
    </div>
</template>
<script>
import CTableWrapper from './Table.vue'
import { mapState } from 'vuex'
const ccxt = require ('ccxt')
const exchange = new ccxt.binance()
import  {doDecryp} from '../../services/secureVault.js'
const fields = [
  'status','symbol','type','side','price','cost','amount','market'
]

  export default {
    //import Binance from 'binance-api-node'
    name: 'openOrders',
      components: {
        CTableWrapper
      },
    data () {
      return {
        fields,
        coinPair: "DIA/USDT",
        msg: 'Binance order query tool',
      }
    },
    methods: {   
      get_orders (secret,coinPair) {
       var market = 'binance'
       let _this = this
       this.fetchOpenOrders(this.bitforex_api_key,secret,coinPair,market).then(function(value) {
            var text = JSON.stringify(value);
            var parsedtext = JSON.parse(text);
            var balances = parsedtext;
            var balances_finales = []
              balances.forEach( function(valor, indice, array) {
                    valor.market =  _thi.market
                    balances_finales.push(valor)
                  // console.log("En el índice " + indice + " hay este valor: " + valor);
                });
          _this.$store.dispatch('startTimer_binance_getallOrders')
           _this.$store.commit('updateCryptoMarketOrders',balances_finales)
          }, function(reason) {
          // rechazo
        })
     }, 
      async fetchOpenOrders (apiKey,secret,coinPair,market) {
          try {
              const instanceEx = new ccxt[market]()
              instanceEx.apiKey = apiKey
              instanceEx.secret =  secret
              instanceEx.enableRateLimit = true
              if(this.timer_binance_getallOrders==0){
                  instanceEx.options["warnOnFetchOpenOrdersWithoutSymbol"] = false 
              }
              instanceEx.proxy ='https://maniacmansion.org/RGBanticors/'
              if (coinPair){
                return await  instanceEx.fetchOpenOrders  (coinPair )
              } else{
                  return await  instanceEx.fetchOpenOrders  ( )
              }
            
          } catch (error) {
              console.log(error)
          }
      },
      queryPairOeders () {
        doDecryp (this.binance_api_secret,this.get_orders,[this.coinPair]) 
      },
      queryAllPairOeders(){
        doDecryp (this.bitforex_api_secret,this.get_orders) 
      },
    },
    mounted () {
     
    },
    computed: {
       ...mapState(['binance_api_key', 'crypto_orders','binance_api_secret','kucoin_api_key','kucoin_api_secret','kucoin_api_password','bitmax_api_key', 'bitmax_api_secret', 'gateio_api_key', 'gateio_api_secret', 'crypto_assets','bitforex_api_key', 'bitforex_api_secret','timer_binance_getallOrders'])
    }
} 


</script>
