import { render, staticRenderFns } from "./exchanges.vue?vue&type=template&id=575b6370&"
import script from "./exchanges.vue?vue&type=script&lang=js&"
export * from "./exchanges.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports