<template>
    <div >
    <h1>{{msg}}</h1>

      <div >
        <input v-model="BinanceApiKey" placeholder="Binance api key"  >
        <input v-model="BinanceApiSecret" placeholder="Binance secret key" >
          <button
            class="btn btn-primary btn-margin"
            @click="saveBinanceApi ()">
            saveBinanceApi
          </button>
      </div >
      <div >
          Current Binance API: {{binance_api_key}}
      </div ><br><br><br>
      <div >
        <input v-model="BitforexApiKey" placeholder="Bitforex api key"  >
        <input v-model="BitforexApiSecret" placeholder="Bitforex secret key" >
          <button
            class="btn btn-primary btn-margin"
            @click="saveBitforexApi ()">
            saveBitforexApi
          </button>
      </div >
      <div >
          Current Bitforex API: {{bitforex_api_key}}
      </div ><br><br><br>
      <div >
        <input v-model="BitmaxApiKey" placeholder="Bitmax api key"  >
        <input v-model="BitmaxApiSecret" placeholder="Bitmax secret key" >
          <button
            class="btn btn-primary btn-margin"
            @click="saveBitmaxApi ()">
            saveBitmaxApi
          </button>
      </div >
      <div >
          Current Bitmax API: {{bitmax_api_key}}
      </div ><br><br><br>
      <div >
        <input v-model="GateioApiKey" placeholder="Gateio api key"  >
        <input v-model="GateioApiSecret" placeholder="Gateio secret key" >
          <button
            class="btn btn-primary btn-margin"
            @click="saveGateioApi ()">
            saveGateioApi
          </button>
      </div >
      <div >
          Current Gateio API: {{gateio_api_key}}
      </div ><br><br><br>
      <div >
        <input v-model="KucoinApiKey" placeholder="Kucoin api key"  >
        <input v-model="KucoinApiSecret" placeholder="Kucoin secret key" >
        <input v-model="KucoinApiPassword" placeholder="Kucoin api password" >
          <button
            class="btn btn-primary btn-margin"
            @click="saveKucoinApi ()">
            saveKucoinApi
          </button>
      </div >
      <div >
          Current Kucoin API: {{kucoin_api_key}}
      </div ><br><br><br>
    </div>
</template>
<script>
import { mapState } from 'vuex'
const ethUtil = require('ethereumjs-util') // For Encrypt Message
const sigUtil = require('eth-sig-util') // For Encrypt Message
import  {doEncryp} from '../../services/secureVault.js'


  export default {
    //import Binance from 'binance-api-node'
    name: 'settings',
    data () {
      return {
        msg: 'API Encrypted Store',
        BinanceApiKey: '',
        BinanceApiSecret: '',
        BitforexApiKey: '',
        BitforexApiSecret: '',
        GateioApiKey: '',
        GateioApiSecret: '',
        BitmaxApiKey: '',
        BitmaxApiSecret: '',
        KucoinApiKey: '',
        KucoinApiSecret: '',
        KucoinApiPassword: '',
      }
    },
    methods: {   
      saveBinanceSecret (encryptedMessage) {
        this.$store.commit('setBinanceApiSecret', encryptedMessage)
        this.BinanceApiKey = ''
        this.BinanceApiSecret = ''
      }, 
      saveBitforexSecret (encryptedMessage) {
        this.$store.commit('setBitforexApiSecret', encryptedMessage)
        this.BitforexApiKey = ''
        this.BitforexApiSecret = ''
      }, 
      saveBitmaxSecret (encryptedMessage) {
        this.$store.commit('setBitmaxApiSecret', encryptedMessage)
        this.BitmaxApiKey = ''
        this.BitmaxApiSecret = ''
      }, 
      saveGateioSecret (encryptedMessage) {
        this.$store.commit('setGateioApiSecret', encryptedMessage)
        this.GateioApiKey = ''
        this.GateioApiSecret = ''
      },
      saveKucoinSecret (encryptedMessage) {
        this.$store.commit('setKucoinApiSecret', encryptedMessage)
        this.KucoinApiKey = ''
        this.KucoinApiSecret = ''
      },
      saveKucoinPassword (encryptedMessage) {
        this.$store.commit('setKucoinApiPassword', encryptedMessage)
        this.KucoinApiPassword = ''
      },
      saveBinanceApi () {
        this.$store.commit('setBinanceApikey', this.BinanceApiKey)
        doEncryp (this.BinanceApiSecret,this.saveBinanceSecret) 
      },
      saveBitforexApi () {
        this.$store.commit('setBitforexApikey', this.BitforexApiKey)
        doEncryp (this.BitforexApiSecret,this.saveBitforexSecret) 
      },
      saveBitmaxApi () {
        this.$store.commit('setBitmaxApikey', this.BitmaxApiKey)
        doEncryp (this.BitmaxApiSecret,this.saveBitmaxSecret) 

      },
      saveGateioApi () {
        this.$store.commit('setGateioApiKey', this.GateioApiKey)
        doEncryp (this.GateioApiSecret,this.saveGateioSecret) 
      },
      saveKucoinApi () {
        this.$store.commit('setKucoinApikey', this.KucoinApiKey)
        doEncryp (this.KucoinApiSecret,this.saveKucoinSecret) 
        doEncryp (this.KucoinApiPassword,this.saveKucoinPassword) 
      },
    },
    mounted () {
     
    },
    computed: {
      ...mapState(['binance_api_key', 'binance_api_secret','kucoin_api_key','kucoin_api_secret','kucoin_api_password','gateio_api_key', 'gateio_api_secret','bitmax_api_key', 'bitmax_api_secret','bitforex_api_key','bitforex_api_secret'])
    }
} 

</script>
